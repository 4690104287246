// components/TransitionLink.js
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { startTransition } from "react";

const TransitionLink = ({ to, children, ...props }) => {
  const handleClick = (e) => {
    e.preventDefault();
    startTransition(() => {
      window.location.href = to;
    });
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...props}>
      {children}
    </RouterLink>
  );
};

export default TransitionLink;