import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Auth } from "../../../pages/auth/Auth";
import Header from "../../components/header/Header";

import "../../../styles/scss/global/global.scss";

import * as Pages from "../routers/page.router";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  if (!user) {
    return <Auth onAuthenticate={setUser} />;
  }

  return (
    <Router>
      <div className="App">
        <Header onLogout={handleLogout} />
        <main>
          <Routes>
            <Route path="/" element={<Pages.Home />} />
            <Route path="/post/:id" element={<Pages.Post />} />
            <Route path="/admin" element={<Pages.Admin />} />
            <Route path="/add" element={<Pages.Add />} />
            <Route path="/edit/:id" element={<Pages.Edit />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
