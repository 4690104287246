import React from "react";
import { TransitionLink } from "../../modules";

const Header = ({ onLogout }) => {

  const handleLogout = () => {
    localStorage.removeItem("user");
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <header>
      <h1>My Blog CMS</h1>
      <nav>
        <ul>
          <li>
            <TransitionLink to="/">Home</TransitionLink>
          </li>
          <li>
            <TransitionLink to="/admin">Admin</TransitionLink>
          </li>
          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
