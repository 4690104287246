// hooks/useAuth.js
import { useState } from 'react'
import { signIn } from './api'

export const useAuth = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)

  const handleSignIn = async () => {
    try {
      const { data, error } = await signIn(email, password)
      if (error) throw error
      localStorage.setItem('user', JSON.stringify(data.user))
      return data.user
    } catch (err) {
      setError(err.message)
    }
  }

  return { email, setEmail, password, setPassword, error, handleSignIn }
}