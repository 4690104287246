// api.js
import { createClient } from '@supabase/supabase-js'

const tableName = "blog";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY

const supabase = createClient(supabaseUrl, supabaseKey)

export const signUp = (email, password) => 
  supabase.auth.signUp({ email, password })

export const signIn = (email, password) =>
  supabase.auth.signInWithPassword({ email, password })

export const fetchPosts = () => 
  supabase.from(tableName).select('*').order('created_at', { ascending: false })

export const getPost = (id) => 
  supabase.from(tableName).select('*').eq('id', id).single()

export const createPost = (postData) => 
  supabase.from(tableName).insert([postData])

export const updatePost = (id, postData) =>
  supabase.from(tableName).update(postData).eq('id', id)

export const deletePost = (id) =>
  supabase.from(tableName).delete().eq('id', id)

// Add other API functions as needed