// components/Auth.js
import React from "react";
import { useAuth } from "../../global/hooks/useAuth";

export const Auth = ({ onAuthenticate }) => {
  const { email, setEmail, password, setPassword, error, handleSignIn } =
    useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await handleSignIn();
    if (user) onAuthenticate(user);
  };

  return (
    <div className="page signin">
      <h2>Sign In</h2>
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Sign In</button>
        </form>
      </div>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};
